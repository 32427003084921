import React from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'

const SideProfile = () => {
  return (
    <StaticQuery
      query={graphql`
        query SideProfileQuery {
          site {
            siteMetadata {
              author
              about
              facebook_url
              twitter_url
              instagram_url
              profile_picture
            }
          }
        }
      `}
      render={data => (
        <aside className="widget about-me-widget  text-center">
          <div className="about-me-content">
            <div className="about-me-img">
              <img
                src={data.site.siteMetadata.profile_picture}
                alt=""
                className="img-me img-circle"
                style={{ maxWidth: '169px', height: 'auto' }}
              />
              <h2 className="text-uppercase">
                {data.site.siteMetadata.author}
              </h2>
              <p>{data.site.siteMetadata.about}</p>
            </div>
          </div>
          <div className="social-share">
            <ul className="list-inline">
              {data.site.siteMetadata.facebook_url ? (
                <li>
                  <a
                    className="s-facebook"
                    href={data.site.siteMetadata.facebook_url}
                  >
                    <i className="fa fa-facebook" />
                  </a>
                </li>
              ) : (
                ''
              )}

              {data.site.siteMetadata.twitter_url ? (
                <li>
                  <a
                    className="s-twitter"
                    href={data.site.siteMetadata.twitter_url}
                  >
                    <i className="fa fa-twitter" />
                  </a>
                </li>
              ) : (
                ''
              )}

              {data.site.siteMetadata.instagram_url ? (
                <li>
                  <a
                    className="s-instagram"
                    href={data.site.siteMetadata.instagram_url}
                  >
                    <i className="fa fa-instagram" />
                  </a>
                </li>
              ) : (
                ''
              )}
            </ul>
          </div>
        </aside>
      )}
    />
  )
}

export default SideProfile
