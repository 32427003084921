import React from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const LatestPosts = () => {
  return (
    <StaticQuery
      query={graphql`
        query LatestPostsQuery {
          remark: allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: 4
          ) {
            posts: edges {
              post: node {
                html
                frontmatter {
                  layout
                  title
                  path
                  category
                  tags
                  description
                  date(formatString: "MMMM Do, YYYY")
                  image {
                    childImageSharp {
                      fluid(maxWidth: 103) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <aside className="widget latest-post-widget">
          {/* data.site.siteMetadata.profile_picture */}
          <h2 className="widget-title text-uppercase text-center">
            Latest Posts
          </h2>
          <ul>
            {data.remark.posts.map(({ post }, i) => (
              <li className="media" key={i}>
                <div className="media-left">
                  <Link className="popular-img" to={post.frontmatter.path}>
                    {post.frontmatter.image.childImageSharp.fluid ? (
                      <Img
                        fluid={post.frontmatter.image.childImageSharp.fluid}
                        style={{}}
                      />
                    ) : (
                      ''
                    )}
                  </Link>
                </div>
                <div className="latest-post-content">
                  <h2 className="text-uppercase">
                    <Link to={post.frontmatter.path}>
                      {post.frontmatter.title}
                    </Link>
                  </h2>
                  <p>{post.frontmatter.date}</p>
                </div>
              </li>
            ))}
          </ul>
        </aside>
      )}
    />
  )
}

export default LatestPosts
